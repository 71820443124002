import React from 'react'
import '../Estilo/Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material'
import logo from "../Images/logo.png"
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate()
  return (
    <footer>
      <div>
        <p className='footerParrafos'>tecniplagaschile@gmail.com</p>
        <p className='footerParrafos'>+56 9 4220 2939</p>
      </div>
      <img className="ImgFooter" src={logo} alt="logo" onClick={()=>navigate("/")}></img>
      <div>
        <p className='footerParrafos'>Región del Bio Bio y Ñuble</p>
        <p className='footerParrafos'>Todos los derechos reservados ©</p>
      </div>
      <nav>
        <div className="nav-link">
          <a href="https://www.facebook.com/CONTROLPLAGASCHILE" target="_blank" rel="noopener noreferrer">
            <IconButton sx={{ color: "white"}}>
              <FacebookIcon></FacebookIcon>
            </IconButton>
          </a>
        </div>
        <div className="nav-link">
          <a href="https://www.instagram.com/tecniplagaschile/" target="_blank" rel="noopener noreferrer">
            <IconButton sx={{ color: "white"}}>
              <InstagramIcon />
            </IconButton>
          </a>
        </div>
      </nav>
    </footer>
  )
}

export default Footer