import { createTheme } from "@mui/material";

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#ffffff',
            light: '#ffffff',
            dark: '#ffffff',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
        },
        text: {
            primary: 'rgba(255,255,255,0.87)',
            secondary: '#ffffff',
        },
    }
});

export default customTheme