import React from 'react';
import "../../Estilo/NuestraEmpresa.css"
import { SITECONTENT } from '../../siteContent';
import { Divider } from '@mui/material';
import Avatars from '../../Components/Avatars';
import PestControlOutlinedIcon from '@mui/icons-material/PestControlOutlined';


const NuestraEmpresaPage = () => {

    return (
        <div className="container-empresa">
            <div className="empresa-header-slide">
                <PestControlOutlinedIcon sx={{color:"#ffffff", fontSize:"35px"}}/>
                <h1>Nuestra empresa</h1>
            </div>
            <Divider></Divider>
            <div className="content-empresa">
                <div className="text-empresa">
                    <p>{SITECONTENT.HISTORIA}</p>
                </div>
                <Avatars></Avatars>
            </div>
        </div>
    );
}

export default NuestraEmpresaPage;
