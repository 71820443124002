import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import "../Estilo/SentForm.css"
import { ThemeProvider } from '@mui/material';
import customTheme from "../Estilo/customTheme.js"

const SentFormPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(()=>navigate("/"),8000)
    }, [navigate]);
    
    return (
        <div className="gracias-container">
            <div className="message">
                <h1>
                    ¡Gracias por confiar en tecniplagas!
                </h1>
                <h3>
                    Nos podremos en contacto pronto.
                </h3>
            </div>
            
            <div className="progress" >
                <ThemeProvider theme={customTheme}>
                    <CircularProgress color="white"/>
                </ThemeProvider>
                
                <h4>Redireccionando al inicio...</h4>
            </div>
            
        </div>
        
    );
}

export default SentFormPage;
