export const SITECONTENT = {
    HISTORIA: "Tecniplagas Chile nace tras los esfuerzos conjuntos de Milton Jimenez y Francisco "+
    "Ramírez, cuyo sueño en común fue establecer una empresa de servicios "+
    "en donde pudiesen contruibuir a la sociedad con su experiencia como funcionarios en distintas "+
    "entidades. Don Milton Jiménez y don Francisco Ramírez se establecen en "+
    "Santa María de Aconcagua (V Región), en donde se ubicó nuestra "+
    "casa matriz en 2017. Actualmente ofrecemos servicios "+
    "desde la región de Valparaíso hasta la Araucanía, con "+
    "sucursal en la ciudad de Talcahuano, siempre apegandonos a la "+
    "normativa vigente a través de certificaciones y el cumplimiento de las "+
    "normativas según establece la ley.",
    MILTON: "67 años, Técnico agrícola "+
    "con basta experiencia en cuidado y natalidad en bovinos, "+
    "más de 20 años trabajando en haciendas administradas por la Armada de "+
    "Chile y con más de 25 años en la empresa de control de "+
    "plagas Allservi Limitada (Concepción).",
    FRANCISCO: "39 años, "+
    "Ingeniero Forestal de la Universidad de Concepción, con "+
    "una experiencia de 20 años en empresas del rubro: Bug`s Hunter "+
    "(Santiago), Serviplagas (Santiago), Todoplagas (Santiago), Allservi "+
    "(Concepción) y Tecniprof (Talcahuano)",
    MISION: "En Tecniplagas, nuestra misión es proteger la salud y "+
    "el bienestar de nuestros clientes al proporcionar soluciones efectivas y "+
    "sostenibles para el control de plagas. Nos comprometemos a ofrecer servicios "+
    "de alta calidad, utilizando métodos seguros y respetuosos con el medio ambiente, "+
    "para crear entornos libres de plagas y promover la tranquilidad en los hogares y "+
    "empresas de nuestra comunidad.",
    VISION: "Aspiramos a ser reconocidos por nuestra excelencia en el servicio y nuestro "+
    "compromiso con la innovación. Buscamos convertirnos en la empresa de elección para "+
    "nuestros clientes, brindando soluciones de vanguardia que superen sus expectativas. "+
    "Queremos ser una de las principales empresas de servicios en control de plagas desde "+
    "la Región de Valparaíso a la Araucanía, y contribuir a un mundo más saludable y "+
    "libre de plagas.",
    INSECTOS: "Nos destacamos en prevenir, controlar y eliminar este tipo de infestacioness en hogares, "+
    "negocios, instalaciones industriales y agrícolas, bajo la premisa de que los insectos, voladores o rastretos, representan "+
    "riesgos para la salud, la propiedad o la calidad de vida.",
    INSECTOS2:" Las técnicas utilizadas dependen del tipo de insecto. Podemos incluir el uso de insecticidas, "+
    "trampas, sellado de entradas, modificaciones estructurales y otros, con el fin de garantizar la erradicación completa "+
    "del problema. Damos garantía de que los productos químicos cumplan con regulaciones ambientales y de seguridad.",
    ROEDORES: "Somos expertos en prevenir, controlar y eliminar infestaciones de roedores "+
    "(Rattus Norvegicus, Rattus Rattus, Mus Musculus), en entornos urbanos, industriales, agrícolas y residenciales. "+
    "Estos pueden representar riesgos para la salud, la seguridad y la propiedad. ",
    ROEDORES2: "Nuestros métodos inclyen la utilización de trampas mecánicas y cebos tóxicos "+
    "para capturar y eliminar roedores, así como también sellado de grietas, agujeros y otras aberturas "+
    "para evitar su ingreso y reducir el acceso a alimentos y refugio.",
    SANITIZACION: "Poseemos procedimientos y técnicas diseñadas para eliminar, reducir o controlar "+
    "microorganismos patógenos, como bacterias, virus, hongos y otros agentes infecciosos. Garantizamos "+
    "ambientes limpios y seguros para la salud pública, a fin de reducir el riesgo de propagación de enfermedades infecciosas.",
    SANITIZACION2:"En nuestros métodos destacan la utilización de desinfectantes, germicidas u otros productos "+
    "químicos adecuados para eliminar microorganismos patógenos específicos. Establecemos programas "+
    "regulares de sanitización, especialmente en áreas críticas o sensibles. "+
    " y damos garantía de que los técnicos y personal involucrado siguen prácticas seguras y utilizan equipo de "+
    "protección personal adecuado.",
    PALOMAS:"En la actualidad, ofrecemos el servicio de prevenir y lidiar con las poblaciones de palomas "+
    "en áreas urbanas y suburbanas de manera segura y efectiva. Estas pueden "+
    "convertirse en una molestia debido a su proliferación y comportamiento invasivo.",
    PALOMAS2:"Nuestros métodos se basan en la eliminación de fuentes de alimento y "+
    "modificaciones estructurales, en conjunto con un seguimiento continuo para "+
    "evaluar su efectividad. Es posible que se necesiten ajustes periódicos para "+
    "mantener a raya las poblaciones de palomas a largo plazo.",
    COMPROMISO:"Tecniplagas se compromete cada dia ofreciendo sus servicios con la "+
    "máxima calidad, respetando las normativas y legislaciones vigentes. Nuestros trabajadores "+
    "son personas completamente capacitadas y licenciadas en la prestación de cada servicio estipulado. ",
    COMPROMISO2:"La siguiente documentación cumple con respaldar a nuestros profesionales, validando "+
    "su formación en el rubro y dando fe de que su problema de plagas está en buenas manos:"
}

