import { Avatar } from '@mui/material';
import React from 'react';
import Aramirez from "../Images/ARamirez.jpg"
import Eramirez from "../Images/ERamirez.jpg"
import Framirez from "../Images/FRamirez.jpg"
import Jgomez from "../Images/JGomez.jpg"
import Mjimenez from "../Images/MJimenez.jpg"
import "../Estilo/WorkerList.css"


const WorkerList = () => {
    return (
        <div className="avatares">
            <Avatar className="avatar-compromiso"  sx={{ width: 48, height: 48 }} alt="Milton Jimenez" src={Mjimenez} ></Avatar>
            <Avatar className="avatar-compromiso"  sx={{ width: 48, height: 48 }} alt="Francisco Ramírez" src={Framirez}></Avatar>
            <Avatar className="avatar-compromiso"  sx={{ width: 48, height: 48 }} alt="Juan Gómez" src={Jgomez}></Avatar>
            <Avatar className="avatar-compromiso"  sx={{ width: 48, height: 48 }} alt="Eleazar Ramírez" src={Eramirez}></Avatar>
            <Avatar className="avatar-compromiso"  sx={{ width: 48, height: 48 }} alt="Abel Ramírez" src={Aramirez}></Avatar>
        </div>
    );
}

export default WorkerList;
