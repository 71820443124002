import "../Estilo/Header.css"
import { AppBar } from '@mui/material';
import React from 'react';
import NavBar from './NavBar';
import logo from "../Images/logo.png"
import { useNavigate } from "react-router-dom";



const Header = () => {
    const navigate = useNavigate()

    return (
        <div>
            <AppBar position="static" color="transparent" >
                <div className="tecni-container" >
                    <div id="header" style={{display:"flex", flexDirection:"row", justifyContent:"center", marginRight:"20px"}}>
                        <img className="slideImg" src={logo} alt="logo" onClick={()=>navigate("/")}></img>
                    </div>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"center",alignItems:"center"}}>
                        <NavBar></NavBar>
                    </div>
                </div>
            </AppBar> 
        </div>
    );
}

export default Header;
