import React from "react";
import "../Estilo/HomePage.css";
import EmailForm from "../Components/Forms/EmailForm";
import cliente1 from "../Images/landes.jpg";
import cliente2 from "../Images/maderasunidas.png";
import cliente3 from "../Images/happygrowing.jpg";
import cliente4 from "../Images/sueñosmagicos.jpg";
import cliente5 from "../Images/suoimi.jpg";
import cliente6 from "../Images/tutumpi.png";
import { useNavigate } from "react-router-dom";
import PestControlOutlinedIcon from "@mui/icons-material/PestControlOutlined";

const HomePage = () => {
  const wtsp = () => {
    window.location.replace("https://wa.me/56942202939");
  };
  const enviar = (formulario) => {
    console.log(formulario);
  };
  const navigate = useNavigate();
  return (
    <div>
      <section className="cotizacion">
        <div className="containerCotizacion">
          <div className="info">
            <div className="tituloP-header-slide">
              
              <h1>Control de plagas en regiones VIII y XVI</h1>
              
            </div>
            <div className="cotizacion-header-slide">
              <h2>Obtén una cotización rápida de nuestros servicios para
                empresas y domicilios</h2>
            </div>
            
            <div className="wtsp-info">
              <div className="wtsp" onClick={wtsp} target="_blank"></div>
            </div>
          </div>
          <div className="form">
            

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                
              }}
            >
              <div className="emailform">
                <div className="titulo-header-slide">
                  <PestControlOutlinedIcon
                    sx={{ color: "#FFFFFF", fontSize: "35px" }}
                  />
                  <h1>Contacto</h1>
                </div>
              <EmailForm enviar={enviar}/>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <section className="servicios">
        <div className="serviciosDiv">
          <div className="titulo-info">
            <div className="titulo-header-slide">
              <PestControlOutlinedIcon
                sx={{ color: "#FFFFFF", fontSize: "35px" }}
              />
              <h1>Servicios</h1>
            </div>
          </div>

          <div className="containerServicios">
            <div className="imgRat">
              <div className="card">
                <div className="content-card">
                  <div className="front">
                    <h1>Desratización</h1>
                  </div>
                  <div className="back" onClick={() => navigate("/servicios")}>
                    <PestControlOutlinedIcon
                      sx={{ color: "#FFFFFF", fontSize: "35px" }}
                    />
                    <p className="parrafo-card">
                      Tecniplagas implementa estrategias para eliminar y
                      prevenir la presencia de ratas y roedores en espacios
                      comerciales, industriales o residenciales.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="imgSan">
              <div className="card">
                <div className="content-card">
                  <div className="front1">
                    <h1>Sanitización</h1>
                  </div>
                  <div className="back1" onClick={() => navigate("/servicios")}>
                    <PestControlOutlinedIcon
                      sx={{ color: "#FFFFFF", fontSize: "35px" }}
                    />
                    <p className="parrafo-card">
                      Tecniplagas se especializa en desinfectar y limpiar
                      exhaustivamente instalaciones, garantizando la eliminación
                      de gérmenes y virus para preservar la salud.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="imgIns">
              <div className="card">
                <div className="content-card">
                  <div className="front2">
                    <h1>Desinsectación</h1>
                  </div>
                  <div className="back2" onClick={() => navigate("/servicios")}>
                    <PestControlOutlinedIcon
                      sx={{ color: "#FFFFFF", fontSize: "35px" }}
                    />
                    <p className="parrafo-card">
                      Tecniplagas ofrece soluciones profesionales para controlar
                      y eliminar plagas de insectos en empresas y hogares,
                      evitando daños y riesgos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="imgPal">
              <div className="card">
                <div className="content-card">
                  <div className="front3">
                    <h1>Control de Palomas</h1>
                  </div>
                  <div className="back3" onClick={() => navigate("/servicios")}>
                    <PestControlOutlinedIcon
                      sx={{ color: "#FFFFFF", fontSize: "35px" }}
                    />
                    <p className="parrafo-card">
                      Tecniplagas gestiona y previene la población de palomas en
                      entornos urbanos y comerciales, reduciendo molestias y
                      protegiendo las instalaciones.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="quienessomos">
        <div className="containerQuSo">
          <div className="titulo-info">
            <div className="contacto-header-slide">
              <PestControlOutlinedIcon
                sx={{ color: "#FFFFFF", fontSize: "35px" }}
              />
              <h1>Clientes</h1>
            </div>
          </div>
          <div className="slider">
            <div className="slide-track">
              <div className="slide">
                <img
                  src={cliente1}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente2}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente3}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente4}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente5}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente6}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente1}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente2}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente3}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente4}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente5}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src={cliente6}
                  width="75%"
                  aspect-ratio="3/2"
                  object-fit="contain"
                  mix-blend-mode="colorburn"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
