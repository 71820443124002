import { Card } from '@mui/material';
import React from 'react';
import { SITECONTENT } from '../siteContent';
import "../Estilo/FlexAnimated.css"

const Servicios = () => {
    return (
        <div className="flex-container">
            
            <div className="text-container desinsectacion" >
                <div className="title-animated">Insectos</div>
                <div className="text-animated servi">
                    <p>{SITECONTENT.INSECTOS}</p>
                    <p>{SITECONTENT.INSECTOS2}</p>
                </div>
            </div>
            
            <div className="text-container desratizacion" >
                <div className="title-animated">Roedores</div>
                <div className="text-animated servi">
                    <p>{SITECONTENT.ROEDORES}</p>
                    <p>{SITECONTENT.ROEDORES2}</p>
                </div>
                
            </div>
            <div className="text-container palomas" >
                    <div className="title-animated">Palomas</div>
                    <div className="text-animated servi">
                        <p>{SITECONTENT.PALOMAS}</p>
                        <p>{SITECONTENT.PALOMAS2}</p>
                    </div>
            </div>
            <div className="text-container sanitizacion" >
                    <div className="title-animated">Sanitización</div>
                    <div className="text-animated servi">
                        <p>{SITECONTENT.SANITIZACION}</p>
                        <p>{SITECONTENT.SANITIZACION2}</p>
                    </div>
            </div>
            
        </div>
    );
}

export default Servicios;
