import React from 'react';
import { SITECONTENT } from '../../siteContent';
import "../../Estilo/FlexAnimated.css"

const MisionVisionPage = () => {
    return (
        
        <div className="flex-container">
            <div className="text-container mision" >
                <div className="title-animated">
                    Misión
                </div>
                <div className="text-animated myv">
                    <p>{SITECONTENT.MISION}</p>
                </div>
            </div>

            <div className="text-container vision" >
                <div className="title-animated">
                    Visión
                </div>
                <div className="text-animated myv">
                    <p>{SITECONTENT.VISION}</p>
                </div>
            </div>
        </div>
        
    );
}

export default MisionVisionPage;
