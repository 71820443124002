import { Avatar } from '@mui/material';
import React from 'react';
import { SITECONTENT } from '../siteContent';
import framirez from "../Images/FRamirez.jpg"
import mjimenez from "../Images/MJimenez.jpg"
import "../Estilo/Avatars.css"


const Avatars = () => {
    return (
        <div class="avatarsContainer">
            <div class="avatar">
                <Avatar className="imgSlideRight" src={mjimenez} sx={{ width: 100, height: 100 }}>M</Avatar>
                <div className="avatar-content">
                    <h4>Milton Jiménez</h4>
                    <p>{SITECONTENT.MILTON}</p>
                </div>
            </div>
            <div class="avatar">
                <Avatar className="imgSlideRight" src={framirez} sx={{ width: 100, height: 100 }}>F</Avatar>
                <div className="avatar-content">
                    <h4>Francisco Ramírez</h4>
                    <p>{SITECONTENT.FRANCISCO}</p>
                </div>
            </div>
        </div>
    );
}

export default Avatars;
