import React from 'react';
import EmailForm from '../Components/Forms/EmailForm';

const ContactoPage = () => {

    const enviar = (formulario) =>{
        console.log(formulario)
    }

    return (
            <div>
                <h1>Contacto</h1>
                <div style={{display:"flex", flexDirection:"row"}}>
                    <h3>Estás a un paso de solucionar tu problema de plagas
                    ¡Déjanos tus datos y nos contactaremos contigo!</h3>
                    <EmailForm enviar={enviar} />
                </div>
            </div>
    );
}

export default ContactoPage;
