import "./App.css";
import Footer from "./Components/Footer";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ErrorPage from './Pages/ErrorPage';
import HomePage from './Pages/HomePage';
import MisionVisionPage from "./Pages/Quienessomos/MisionVisionPage"
import ContactoPage from "./Pages/ContactoPage"
import SentFormPage from './Pages/SentFormPage';
import Header from "./Components/Header";
import NuestraEmpresaPage from "./Pages/Quienessomos/NuestraEmpresaPage";
import ServiciosPage from "./Pages/ServiciosPage";
import CompromisoPage from "./Pages/Quienessomos/CompromisoPage";

function App() {
  return (
    <div>
      <Router>
        <aside>
          <Header />
        </aside>
        <main>
          

            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/servicios" element={<ServiciosPage />} />
              <Route path="/compromiso" element={<CompromisoPage />} />
              <Route path="/nuestraempresa" element={<NuestraEmpresaPage />} />
              <Route path="/mision-vision" element={<MisionVisionPage />} />
              <Route path="/contacto" element={<ContactoPage />} />
              <Route path="/gracias" element={<SentFormPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>

            
          

        </main>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
