import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiTelInput } from 'mui-tel-input'
import customTheme from "../../Estilo/MuiTextFieldCustom.js"
import { ThemeProvider } from '@mui/material';
import "../../Estilo/EmailForm.css"

const EmailForm = (props) => {

    const [url, setUrl] = useState("http://localhost:3000/gracias");
    const [phone, setPhone] = useState("+56");

    useEffect(() => {
        if (window.location.hostname !== "localhost"){
            setUrl("https://" + window.location.hostname + "/gracias")
        }
      }, []);


    const handlePhone = (value,info) => {
        setPhone(value)
    }

    return (
       
        <form action="https://formsubmit.co/08b830c8e74956d0c218eb2f69593510" method="POST">
            
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <ThemeProvider theme={customTheme}>
                <div style={{ display:"flex", flexDirection:"column"}}>
                    <TextField  required variant="outlined" name="Nombre" label="Nombre (Persona o empresa)" 
                                color="primary" 
                                sx={{ m:0.5}}
                                />
                    <TextField  required variant="outlined" name="Email" label="E-mail" 
                                color="primary" 
                                sx={{ m:0.5}}

                                />
                    <TextField required variant="outlined" name="Asunto" label="Asunto" 
                                color="primary" 
                                sx={{ m:0.5}}

                                />

                    <TextField required color='primary' id="outlined-basic" variant="outlined" name="Comentarios" label="Comentarios"
                            multiline rows={4}
                            sx={{ m:0.5}}
                            />
                    <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                        <MuiTelInput required color='primary' variant="outlined" name="Teléfono" value={phone} label="Teléfono"
                                    disableDropdown defaultCountry="CL" sx={{ m:0.5}}
                                    onChange={handlePhone}></MuiTelInput>
                        
                    </div>
                   
                    
                </div>
                </ThemeProvider>
                <div className="btnSubmit">
                    <Button type="submit" variant="contained" sx={{m:0.5}}>Enviar consulta</Button>
                </div>
            </div>
            
            <input type="hidden" name="_next" value={url}></input>
            <input type="hidden" name="_captcha" value="false"></input>
            <input type="hidden" name="_template" value="table"></input>
        </form>

    );
}

EmailForm.propTypes = {
    enviar: PropTypes.func.isRequired
}

export default EmailForm;
