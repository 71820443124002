import React from 'react';
import PestControlOutlinedIcon from '@mui/icons-material/PestControlOutlined';
import { Divider, Link, List, ListItem } from '@mui/material';
import { SITECONTENT } from '../../siteContent';
import WorkerList from '../../Components/WorkerList';
import "../../Estilo/Compromiso.css"
import aramirez from "./pdf/aramirez-plagas.pdf"
import framirez from "./pdf/framirez-plagas.pdf"
import jgomez from "./pdf/jgomez-plagas.pdf"

const CompromisoPage = () => {

    return (
        <div className="container-compromiso">
            <div className="compromiso-header">
                <div className="compromiso-header-slide">
                    <PestControlOutlinedIcon sx={{color:"#ffffff", fontSize:"35px"}}/>
                    <h1>Compromiso</h1>
                </div>
                
            </div>
            <Divider></Divider>
            <div className="content-compromiso">
                <div className="text-compromiso">
                    <p>{SITECONTENT.COMPROMISO}</p>
                    <p>{SITECONTENT.COMPROMISO2}</p>
                </div>
                <div className="list-links">
                    <div className="worker-list">
                        <WorkerList/>
                    </div>
                    <List>
                        <ListItem>
                            <PestControlOutlinedIcon sx={{color:"#ffffff", fontSize:"17px", marginLeft:"25px", marginRight:"5px"}}/>
                            <Link href={aramirez} download="aramirez-plagas.pdf" sx={{fontSize:"20px"}} underline='hover' >
                                Abel Ramírez - Certificado de control de plagas urbanas
                            </Link>
                        </ListItem>
                        <ListItem>
                            <PestControlOutlinedIcon sx={{color:"#ffffff", fontSize:"17px", marginLeft:"25px", marginRight:"5px"}}/>
                            <Link href={framirez} download="framirez-plagas.pdf" sx={{fontSize:"20px"}} underline="hover">
                                Francisco Ramírez - Certificado de control de plagas urbanas
                            </Link>
                        </ListItem>
                        <ListItem>
                            <PestControlOutlinedIcon sx={{color:"#ffffff", fontSize:"17px", marginLeft:"25px", marginRight:"5px"}}/>
                            <Link href={jgomez} download="jgomez-plagas.pdf" sx={{fontSize:"20px"}} underline="hover" >
                                Juan Gómez - Certificado de control de plagas urbanas
                            </Link>
                        </ListItem>
                        
                    </List>

                </div>
            </div>
        </div>
    );
}

export default CompromisoPage;
