import React from 'react';
import { Button, ButtonGroup, Menu, MenuItem, ThemeProvider} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import customTheme from '../Estilo/customTheme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const NavBar = () => {
    const navigate = useNavigate()



    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };
    const handleClose = () => {
        setAnchor(null);
    };

    return (
        <div className="appearingNav">
            <ThemeProvider theme={customTheme}>
                <ButtonGroup  color="graywhite" size="large" variant="text" >
                    <Button onClick={()=>navigate("/")}>Inicio</Button>
                    <Button  id="basic-button2" aria-controls={open ? 'basic-menu2' : undefined}
                            aria-haspopup="true" aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick} endIcon={<ArrowDropDownIcon/>}>Quienes somos</Button>
                    <Menu disableScrollLock={true} id="basic-menu2" anchorEl={anchor} open={open}
                        onClose={handleClose} MenuListProps={{'aria-labelledby': 'basic-button2',}}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}
                        transformOrigin={{vertical: 'top', horizontal: 'left'}}>
                        <MenuItem onClick={()=>{
                            handleClose()
                            navigate("/nuestraempresa")
                        }}>Nuestra empresa</MenuItem>
                        <MenuItem onClick={()=>{
                            handleClose()
                            navigate("/mision-vision")
                        }}>Misión y visión</MenuItem>
                        <MenuItem onClick={()=>{
                            handleClose()
                            navigate("/compromiso")
                        }}>Compromiso</MenuItem>
                    </Menu>
                    <Button color="graywhite" onClick={()=>navigate("/servicios")}>Servicios</Button>
                    
                    
                </ButtonGroup>
            </ThemeProvider>
        </div>
        
                
            
        
    );
}

export default NavBar;
